<script>
import { mapGetters } from 'vuex';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import RemoteSearchInput from '@/components/Ui/RemoteSearchInput.vue';
import SecondEquipmentMapping
  from '@/views/EventsAndIncidents/EventMapping/SecondEquipmentMapping.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import functionCardsApi from '@/api/eventsAndIncidents/functionCards';
import udFunctionCardsApi from '@/api/eventsAndIncidents/userDataEvents/functionCards';


export default {
  name: 'SecondEquipmentPanelMapping',
  components: {
    SecondEquipmentMapping,
    RemoteSearchInput,
  },
  mixins: [formatters, dateHelpers],
  props: ['myStore'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: false,
      organizationalReasonsRequired: false,
      editMode: false
    };
  },
  validations() {
    const {
      estimationValidation,
      orgReasonsValidation,
      technicalReasonValidation,
      orgReasonsRequiredValidation
    } = this.myStore;
    const localRules = {
      selectedDestSepCard: {
        statusId: {
          required: helpers.withMessage('Поле обязательно для заполнения', required),
          $autoDirty: true
        },
        estimationId: {
          required: helpers.withMessage('Поле обязательно для заполнения', required),
          estimationValidation,
          $autoDirty: true
        },
        // secondEquipmentsInit: {
        //   required: helpers.withMessage('Поле обязательно для заполнения', required),
        //   $autoDirty: true
        // },
        organizationalReasons:
          {
            orgReasonsValidation,
            // required: helpers.withMessage('Поле обязательно для заполнения', orgReasonsRequiredValidation),
            orgReasonsRequiredValidation,
            $autoDirty: true
          },
        technicalReasons:
          {
            // required: helpers.withMessage('Поле обязательно для заполнения', technicalReasonValidation),
            technicalReasonValidation,
            $autoDirty: true
          },

      },
    };

    return localRules;
  },
  computed: {
    ...mapGetters('identity', ['user', 'userHasRole', 'userHasRoleGroup']),
    ...mapGetters('dictionaries', ['cardInformations', 'misoperationReasons', 'estimations']),
    sepCompare() {
      if (this.myStore.state.selectedSepId) {
        return this.myStore.state.sepCompare.get(this.myStore.state.selectedSepId) || {};
      }
      return {};
    },
    selectedDestSepCard() {
      return this.myStore.state.selectedDestSepCard;
    },
    selectedSourceSepCard() {
      return this.myStore.state.selectedSourceSepCard;
    },
    showDestSep() {
      return this.myStore.state.selectedDestSepCard;
    },
    showSourceSep() {
      return this.myStore.state.selectedSourceSepCard;
    },
    destStaffCategories() {
      return this.myStore.state.destEventCard?.staffCategories || [];
    },
    sourceStaffCategories() {
      return this.myStore.state.sourceEventCard?.staffCategories || [];
    },
    destStaffCulpabilities() {
      return this.myStore.state.destEventCard?.staffCulpabilities || [];
    },
    sourceStaffCulpabilities() {
      return this.myStore.state.sourceEventCard?.staffCulpabilities || [];
    },
    showCopyAll() {
      return !this.selectedDestSepCard && this.myStore.state.selectedSourceSepCard && this.myStore.state.destEventCard;
    },
    allowedCorSepStatuses() {
      if (!this.selectedDestSepCard) {
        return [];
      }
      return this.myStore.getAllowedCorSepStatuses(this.selectedDestSepCard.currentStatusId);
    },
    destSes() {
      return this.myStore.state.hideRepeat ? this.selectedDestSepCard.secondEquipmentCards.filter((sep) => !sep.isEqual) : this.selectedDestSepCard.secondEquipmentCards;
    },
    sSes() {
      return this.myStore.state.hideRepeat ? this.selectedSourceSepCard.secondEquipmentCards.filter((sep) => !sep.isEqual) : this.selectedSourceSepCard.secondEquipmentCards;
    },
    sepHideRepeat() {
      return this.myStore.state.hideRepeat;
    }
  },
  methods: {
    addDestNewRow() {
      this.selectedDestSepCard.organizationalReasons.unshift({
        key: this.newRandomKey(),
        code: '',
        category: '',
        organization: ''
      });
    },
    deleteDestRow(idx) {
      this.$delete(this.selectedDestSepCard.organizationalReasons, idx);
    },
    newRandomKey() {
      return new Date().getTime() + Math.floor(Math.random() * 10000);
    },
    copyData(name) {
      if (name === 'secondEquipmentPanelName') {
        this.myStore.copySep();
        // if (!this.selectedDestSepCard && this.myStore.state.selectedSourceSepCard) {
        //
        //   const addsep = JSON.parse(JSON.stringify(this.myStore.state.selectedSourceSepCard));
        //   // addsep.sourceSepCardId = addsep.id;
        //   addsep.id = '00000000-0000-0000-0000-000000000000';
        //   const initSt = this.myStore.getInitCorSepStatus();
        //   addsep.statusName = initSt.name;
        //   addsep.statusId = initSt.id;
        //   addsep.currentStatusId = initSt.id;
        //   addsep.secondEquipmentCards.forEach((el) => {
        //     el.id = '00000000-0000-0000-0000-000000000000';
        //   });
        //   this.myStore.state.destEventCard.secondEquipmentPanelCards.push(addsep);
        // }
      }
      if (this.selectedDestSepCard && this.myStore.state.selectedSourceSepCard) {
        this.selectedDestSepCard[name] = JSON.parse(JSON.stringify({ v: this.myStore.state.selectedSourceSepCard[name] })).v;
debugger;
        // eslint-disable-next-line default-case
        switch (name) {
          case 'organizationalReasons':
            this.myStore.prepareOrganizationalReasons(this.selectedDestSepCard);
            break;
        }
        //  this.myStore.reload();
      }
    },
    async validateForm() {
      try {
        await this.$refs.cardForm.validate();
        return Promise.resolve(true);
      } catch (e) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(false);
      }
    },

    async validate() {
      const r2 = await this.$refs.seRef.validate();
      return !this.v$.$invalid && r2;
    },
    handleTabsEdit(targetName, action) {
      if (action === 'remove') {
        const tab = this.destSes.find((el) => el.secondEquipmentId === targetName);
        if (tab.id === this.myStore.GUID_EMPTY) {
          this.selectedDestSepCard.secondEquipmentCards.splice(tab, 1);
          if (this.selectedDestSepCard.secondEquipmentCards.length === 0) {
            this.myStore.state.destEventCard.secondEquipmentPanelCards = this.myStore.state.destEventCard.secondEquipmentPanelCards.filter((el) => el !== this.selectedDestSepCard);
          }
          return;
        }
        if (tab && tab.id) {
          this.functionCardDelete(tab.id);
        }
      }
    },

    async functionCardDelete(id) {
      let udExists;
      if (this.userHasRoleGroup('ExternalUser')) {
        udExists = false;
      } else {
        udExists = await functionCardsApi.checkUserDataFunctionCards(id);
      }

      if (udExists.data) {
        await this.$confirm('Вы действительно хотите удалить запись? Функция РЗА связана с данными внешнего пользователя!', 'Удаление записи!', {
          confirmButtonText: 'ОК',
          cancelButtonText: 'Отмена',
          type: 'warning',
        });
        const deleteAction = this.userHasRoleGroup('ExternalUser')
          ? udFunctionCardsApi.deleteFunctionCard
          : functionCardsApi.deleteFunctionCard;
        this.loading = true;
        try {
          await deleteAction(id);
          await this.myStore.loadByIdAsync(this.myStore.state.currentCorrelationId);
          this.$message.success('Удалено успешно');
        } catch (e) {
          this.$message.error(e.message);
        } finally {
          this.loading = false;
        }
      } else {
        await this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
          confirmButtonText: 'ОК',
          cancelButtonText: 'Отмена',
          type: 'warning',
        });
        const deleteAction = this.userHasRoleGroup('ExternalUser')
          ? udFunctionCardsApi.deleteFunctionCard
          : functionCardsApi.deleteFunctionCard;
        this.loading = true;

        try {
          await deleteAction(id);
          await this.myStore.loadByIdAsync(this.myStore.state.currentCorrelationId);
          this.$message.success('Удалено успешно');
        } catch (e) {
          this.$message.error(e.message);
        } finally {
          this.loading = false;
        }
      }
    },
    handleSepStatusChange(stateId) {
      debugger;
      if (stateId) {
        const al = this.allowedCorSepStatuses.find((el) => el.id === stateId);
        if (!al) {
          this.selectedDestSepCard.statusId = null;
        }
      }

      this.myStore.handleSepStatusChange();
    }
  }
};
</script>

<template>
  <div class="sep-mapper" v-if="showDestSep || showSourceSep">
    <el-row :gutter="5" class="mt-1">
      <el-col :span="12">
        <div v-if="showDestSep">
          <el-row :gutter="5" class="mt-1 my-label-14">
            <el-col :span="12" class="my-label-14">Текущий статус сопоставления УРЗА:</el-col>
            <el-col :span="12">
              {{ selectedDestSepCard.statusName }}
            </el-col>
          </el-row>
          <el-row :gutter="5" class="mt-1 my-label-14">
            <el-col :span="12" class="my-label-14">Установить статус сопоставления УРЗА:
              <span v-show="v$.selectedDestSepCard.statusId.required"
                    style="color: #f56c6c">*</span>
            </el-col>
            <el-col :span="12">
              <el-select
                popper-class="search-select"
                size="mini"
                v-model="selectedDestSepCard.statusId" placeholder="">
                <el-option
                  v-for="item in allowedCorSepStatuses"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <div v-if="v$.selectedDestSepCard.statusId.required.$invalid"
                   class="my-error">
                {{ v$.selectedDestSepCard.statusId.required.$message }}
              </div>
            </el-col>
          </el-row>
        </div>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1">&nbsp;</el-col>
      <el-col :span="11">
        <el-row :gutter="5" class="mt-1 my-label-14" v-if="showSourceSep">
          <el-col :span="12">Текущий статус сопоставления УРЗА:</el-col>
          <el-col :span="12">
            {{ selectedSourceSepCard.statusName }}
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="5" class="mt-1">
      <el-col :span="12">
        <el-divider v-if="showDestSep"></el-divider>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :offset="1" :span="11">
        <el-divider v-if="showSourceSep"></el-divider>
        <span v-else>&nbsp;</span>
      </el-col>
    </el-row>
    <el-row :gutter="5" class="mt-1">
      <el-col :span="12">
        <el-row :gutter="5" class="mt-0" v-if="showDestSep">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Устройство РЗА:</el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-input
              readonly
              :class="{'my-event-card-no-compare': sepCompare.secondEquipmentPanelName }"
              size="mini"
              v-model="selectedDestSepCard.secondEquipmentPanelName"
            ></el-input>
          </el-col>
        </el-row>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1" class="my-flex-content-center">
        <el-button v-if="showCopyAll" size="mini"
                   @click="copyData('secondEquipmentPanelName')"
                   :class="{'my-event-card-move': sepCompare.secondEquipmentPanelName }"
                   icon="el-icon-d-arrow-left" circle></el-button>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <el-row :gutter="5" class="mt-0" v-if="showSourceSep">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Устройство РЗА:</el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-input
              :class="{'my-event-card-no-compare': sepCompare.secondEquipmentPanelName }"
              size="mini"
              v-model="selectedSourceSepCard.secondEquipmentPanelName"
              readonly
            ></el-input>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="5" class="mt-1">
      <el-col :span="12">
        <el-row :gutter="5" class="mt-0" v-if="showDestSep">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Энергообъект:</el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-input
              readonly
              :class="{'my-event-card-no-compare': sepCompare.energyObjectName }"
              size="mini"
              v-model="selectedDestSepCard.energyObjectName"
            ></el-input>
          </el-col>
        </el-row>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1">
        <!--        <el-button size="mini" v-if="showSourceSep && sepCompare.energyObjectName"-->
        <!--                   :class="{'my-event-card-move': sepCompare.energyObjectName }"-->
        <!--                   icon="el-icon-d-arrow-left"-->
        <!--                   circle></el-button> -->
        <span>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <el-row :gutter="5" class="mt-0" v-if="showSourceSep">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Энергообъект:</el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-input
              size="mini"
              :class="{'my-event-card-no-compare': sepCompare.energyObjectName }"
              v-model="selectedSourceSepCard.energyObjectName"
              readonly
            ></el-input>
          </el-col>
        </el-row>
      </el-col>

    </el-row>
    <el-row :gutter="5" class="mt-1" 
            v-if="!sepHideRepeat ||(sepHideRepeat && sepCompare.estimationId)">
      <el-col :span="12">
        <el-row :gutter="5" class="mt-0" v-if="showDestSep">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Оценка действия:
            <span v-show="v$.selectedDestSepCard.estimationId.required"
                  style="color: #f56c6c">*</span></el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <template v-slot:label>
              Оценка действия:
            </template>


            <el-select
              :class="{'my-event-card-no-compare': sepCompare.estimationId }"
              popper-class="search-select"
              size="mini"
              v-model="selectedDestSepCard.estimationId" placeholder="">
              <el-option
                v-for="item in estimations"
                :key="item.id"
                :label="item.code + ' (' + item.name + ')'"
                :value="item.id">
              </el-option>
            </el-select>
            <div v-if="v$.selectedDestSepCard.estimationId.required.$invalid"
                 class="my-error">
              {{ v$.selectedDestSepCard.estimationId.required.$message }}
            </div>
          </el-col>
        </el-row>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1" class="my-flex-content-center">
        <el-button size="mini" v-if="showSourceSep && sepCompare.estimationId"
                   icon="el-icon-d-arrow-left"
                   :class="{'my-event-card-move': sepCompare.estimationId }"
                   @click="copyData('estimationId');"
                   circle></el-button>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <el-row :gutter="5" class="mt-0" v-if="showSourceSep">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Оценка действия:</el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select disabled
                       :class="{'my-event-card-no-compare': sepCompare.estimationId }"
                       popper-class="search-select"
                       size="mini"
                       v-model="selectedSourceSepCard.estimationId" placeholder="">
              <el-option

                v-for="item in estimations"
                :key="item.id"
                :label="item.code + ' (' + item.name + ')'"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>

    </el-row>
    <el-row :gutter="5" class="mt-1"
            v-if="!sepHideRepeat ||(sepHideRepeat && sepCompare.cardInformation)">
      <el-col :span="12">
        <el-row :gutter="5" class="mt-0" v-if="showDestSep">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Дополнительная
            информация:
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select multiple v-model="selectedDestSepCard.cardInformation" key="destCardInformation"
                       :class="{'my-event-card-no-compare': sepCompare.cardInformation }"
                       size="mini" placeholder="">
              <el-option
                v-for="item in cardInformations"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :disabled="item.isDisabled"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1" class="my-flex-content-center">
        <el-button size="mini" v-if="showSourceSep && sepCompare.cardInformation"
                   icon="el-icon-d-arrow-left"
                   :class="{'my-event-card-move': sepCompare.cardInformation }"
                   @click="copyData('cardInformation')"
                   circle></el-button>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <el-row :gutter="5" class="mt-0" v-if="showSourceSep">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Дополнительная
            информация:
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select multiple v-model="selectedSourceSepCard.cardInformation" key="sourceCardInformation" disabled
                       :class="{'my-event-card-no-compare': sepCompare.cardInformation }"
                       size="mini" placeholder="">
              <el-option
                disabled
                v-for="item in cardInformations"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="5" class="mt-1"
            v-if="!sepHideRepeat ||(sepHideRepeat && sepCompare.technicalReasons)">
      <el-col :span="12">
        <el-row :gutter="5" class="mt-0" v-if="showDestSep">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Технические причины:
            <span
              v-if="v$.selectedDestSepCard.technicalReasons.technicalReasonValidation.$response.required"
              style="color: #f56c6c">*</span>
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select multiple key="destTechnicalReasons"
                       size="mini"
                       v-model="selectedDestSepCard.technicalReasons"
                       popper-class="search-select"
                       :class="{'my-event-card-no-compare': sepCompare.technicalReasons }"
            >
              <el-option
                v-for="item in this.myStore.state.techReasons"
                :key="item.id"
                :label="'('+item.code+') '+item.name"
                :value="item.id"></el-option>
            </el-select>
            <div
              v-if="v$.selectedDestSepCard.technicalReasons.technicalReasonValidation.$response.required && v$.selectedDestSepCard.technicalReasons.$invalid"
              class="my-error">
              {{
                v$.selectedDestSepCard.technicalReasons.technicalReasonValidation.$response.$message
              }}
            </div>
          </el-col>
        </el-row>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1" class="my-flex-content-center">
        <el-button size="mini" v-if="showSourceSep && sepCompare.technicalReasons"
                   icon="el-icon-d-arrow-left"
                   :class="{'my-event-card-move': sepCompare.technicalReasons }"
                   @click="copyData('technicalReasons')"
                   circle></el-button>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <el-row :gutter="5" class="mt-0" v-if="showSourceSep">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Технические причины:
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select multiple disabled key="sourceTechnicalReasons"
                       size="mini"
                       v-model="selectedSourceSepCard.technicalReasons"
                       popper-class="search-select"
                       :class="{'my-event-card-no-compare': sepCompare.technicalReasons }"
            >
              <el-option
                v-for="item in this.myStore.state.techReasons"
                :key="item.id"
                :label="'('+item.code+') '+item.name"
                :value="item.id"></el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="5" class="mt-1"
            v-if="!sepHideRepeat ||(sepHideRepeat && sepCompare.misoperationReasons)">
      <el-col :span="12">
        <el-row :gutter="5" class="mt-0" v-if="showDestSep">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Детал. прич. непр.
            работы:
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select popper-class="search-select" size="mini" key="destMisoperationReasons"
                       v-model="selectedDestSepCard.misoperationReasons"
                       multiple placeholder="">
              <el-option
                v-for="item in misoperationReasons"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1" class="my-flex-content-center">
        <el-button v-if="showSourceSep && sepCompare.misoperationReasons"
                   @click="copyData('misoperationReasons')"
                   size="mini" icon="el-icon-d-arrow-left"
                   :class="{'my-event-card-move': sepCompare.misoperationReasons }"
                   circle></el-button>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <el-row :gutter="5" class="mt-0" v-if="showSourceSep">
          <el-col :span="this.myStore.state.labelSpan" class="my-label-14">Детал. прич. непр.
            работы:
          </el-col>
          <el-col :span="this.myStore.state.dataSpan">
            <el-select popper-class="search-select" size="mini" disabled key="sourceMisoperationReasons"
                       v-model="selectedSourceSepCard.misoperationReasons" multiple placeholder="">
              <el-option
                :class="{'my-event-card-no-compare': sepCompare.misoperationReasons }"

                v-for="item in misoperationReasons"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="5" class="mt-1"
            v-if="!sepHideRepeat ||(sepHideRepeat && sepCompare.organizationalReasons)">
      <el-col :span="12">
        <div v-if="showDestSep">
          <el-row :gutter="5" class="mt-0">
            <el-col :span="24">
              <label class="el-form-item__label">
                Организационные причины:
                <span
                  v-if="v$.selectedDestSepCard.organizationalReasons.orgReasonsRequiredValidation.$response.required"
                  style="color: #f56c6c">*</span>
              </label>
              <el-button
                circle
                icon="el-icon-plus"
                size="small"
                class="p-1 mb-1 ml-2"
                @click="addDestNewRow">
              </el-button>
            </el-col>
          </el-row>
          <el-row :gutter="5" class="mt-0">
            <el-col :span="24">
              <el-table
                :data="this.selectedDestSepCard.organizationalReasons"
                v-loading="this.loading"
                size="mini"
                :default-sort="{prop: 'code', order: 'ascending'}"
                style="width: 100%"
              >
                <el-table-column prop="code" label="Код причины">
                  <template slot-scope="props">
                    <el-select size="small" popper-class="search-select" v-model="props.row.code"
                               placeholder="Выберете код причины">
                      <template v-for="group in destStaffCulpabilities">
                        <el-option-group
                          v-if="group.children.length > 0"
                          :key="group.id"
                          :label="group.name">
                          <el-option
                            v-for="item in group.children"
                            :key="item.id"
                            :label="item.code + ' (' + item.name + ')'"
                            :value="item.code">
                          </el-option>
                        </el-option-group>
                        <el-option
                          v-else
                          :key="group.id"
                          :label="group.code + ' (' + group.name + ')'"
                          :value="group.code">
                        </el-option>
                      </template>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="category" label="Категория персонала">
                  <template slot-scope="props">
                    <el-select size="small" popper-class="search-select"
                               v-model="props.row.category" placeholder="Выберете категорию">
                      <el-option
                        v-for="item in destStaffCategories"
                        :key="item.id"
                        :label="item.code + ' (' + item.name + ')'"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="organization" label="Организация">
                  <template slot-scope="scope">
                    <remote-search-input
                      v-model="scope.row.organization"
                      :key="scope.row.key"
                      searchUrl="/api/organizations/SearchByName"
                      size="small"
                      return-prop="id"
                      placeholder="Выберите организацию"
                      :show-when-init="true"
                      :initial-value="scope.row.organizationName"
                      :start-length=1
                      :allow-create="false"
                      @label-update="scope.row.organizationName = $event"
                    />
                  </template>
                </el-table-column>
                <el-table-column width="42px">
                  <template slot-scope="scope">
                    <el-button @click="deleteDestRow(scope.$index)" type="text"
                               icon="el-icon-delete"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
          <div class="my-error"
               v-if="v$.selectedDestSepCard.organizationalReasons.orgReasonsRequiredValidation.$response.required && v$.selectedDestSepCard.organizationalReasons.orgReasonsRequiredValidation.$invalid">
            {{
              v$.selectedDestSepCard.organizationalReasons.orgReasonsRequiredValidation.$response.$message
            }}
          </div>
          <div class="my-error"
               v-if="v$.selectedDestSepCard.organizationalReasons.orgReasonsValidation.$invalid">
            {{
              v$.selectedDestSepCard.organizationalReasons.orgReasonsValidation.$response.$message
            }}
          </div>
        </div>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="1" class="my-flex-content-center">
        <el-button size="mini" v-if="showSourceSep && sepCompare.organizationalReasons"
                   @click="copyData('organizationalReasons')"
                   :class="{'my-event-card-move': sepCompare.organizationalReasons }"
                   icon="el-icon-d-arrow-left" circle></el-button>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="11">
        <div v-if="showSourceSep">
          <el-row :gutter="5" class="mt-0">
            <el-col :span="24">
              <label class="el-form-item__label">
                Организационные причины: <span v-if="organizationalReasonsRequired"
                                               style="color: #f56c6c">*</span>
              </label>
            </el-col>
          </el-row>
          <el-row :gutter="5" class="mt-0">
            <el-col :span="24">
              <el-table
                :data="this.selectedSourceSepCard.organizationalReasons"
                v-loading="this.loading"
                size="small"
                :default-sort="{prop: 'code', order: 'ascending'}"
                style="width: 100%"
              >
                <el-table-column prop="code" label="Код причины">
                  <template slot-scope="props">
                    <el-select :disabled="true" size="small" popper-class="search-select"
                               v-model="props.row.code" placeholder="Выберете код причины">
                      <template v-for="group in sourceStaffCulpabilities">
                        <el-option-group
                          v-if="group.children.length > 0"
                          :key="group.id"
                          :label="group.name">
                          <el-option
                            v-for="item in group.children"
                            :key="item.id"
                            :label="item.code + ' (' + item.name + ')'"
                            :value="item.code">
                          </el-option>
                        </el-option-group>
                        <el-option
                          v-else
                          :key="group.id"
                          :label="group.code + ' (' + group.name + ')'"
                          :value="group.code">
                        </el-option>
                      </template>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="category" label="Категория персонала">
                  <template slot-scope="props">
                    <el-select :disabled="true" size="small" popper-class="search-select"
                               v-model="props.row.category" placeholder="Выберете категорию">
                      <el-option
                        v-for="item in sourceStaffCategories"
                        :key="item.id"
                        :label="item.code + ' (' + item.name + ')'"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="organization" label="Организация">
                  <template slot-scope="scope">
                    <remote-search-input
                      v-model="scope.row.organization"
                      :key="scope.row.key"
                      :disabled="true"
                      searchUrl="/api/organizations/SearchByName"
                      size="small"
                      return-prop="id"
                      placeholder="Выберите организацию"
                      :show-when-init="true"
                      :initial-value="scope.row.organizationName"
                      :start-length=1
                      :allow-create="false"
                      @label-update="scope.row.organizationName = $event"
                    />
                  </template>
                </el-table-column>
                <el-table-column width="42px">
                  <template slot-scope="scope">
                    <el-button v-if="editMode" @click="deleteRow(scope.$index)" type="text"
                               icon="el-icon-delete"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </div>
        <span v-else>&nbsp;</span>
      </el-col>

    </el-row>
    <el-row :gutter="5">
      <el-col :span="11">
        <!-- :editable="selectedDestSepCard.id!== myStore.GUID_EMPTY && myStore.state.currentCorrelationId  && myStore.state.currentCorrelationId!== myStore.GUID_EMPTY" -->
        <el-tabs v-if="showDestSep" v-model="myStore.state.selectedSeId"
                editable 
                 @edit="handleTabsEdit"
                 @tab-click="myStore.handleSeChange">
          <el-tab-pane v-for="(se, i) in destSes" :key="i"
                       :label="se.secondEquipmentName.substr(0,25)+'...'"
                       :name="se.secondEquipmentId"
          >
          </el-tab-pane>
        </el-tabs>
        <span v-else>&nbsp;</span>
      </el-col>
      <el-col :span="2">&nbsp;</el-col>
      <el-col :span="11">
        <el-tabs v-if="showSourceSep" v-model="myStore.state.selectedSeId"
                 @tab-click="myStore.handleSeChange">
          <el-tab-pane v-for="(se, i) in sSes" :key="i"
                       :label="se.secondEquipmentName.substr(0,25)+'...'"
                       :name="se.secondEquipmentId"
          >
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <second-equipment-mapping :my-store="myStore" ref="seRef"></second-equipment-mapping>
  </div>
</template>


<style>
.el-main {
  overflow-y: auto !important;
}

.sep-mapper .el-tabs__new-tab {
  display: none;
}

.my-event-card-no-compare .el-input__inner {
  color: #D65A31 !important;
}

.my-event-card-no-compare .el-textarea__inner {
  color: #D65A31 !important;
}

.my-event-card-move .el-icon-d-arrow-left {
  color: #D65A31;
}

</style>
